<template>
  <div>
    <!-- Botão para Atualizar Status -->
    <v-menu
      transition="slide-y-transition"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2"
          rounded
          outlined
          dark
          v-on="on"
          v-bind="attrs"
        >
          Atualizar status
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="openConfirmation('status', item.value)"
          v-for="(item, i) in statusItems"
          :key="i"
          link
        >
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      class="ml-2 white primary--text"
      rounded
      @click="openDeleteConfirmation"
    >
      Excluir
      <v-icon right>fas fa-trash</v-icon>
    </v-btn>

    <v-dialog
      v-model="confirmationDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">{{ confirmationTitle }}</v-card-title>
        <v-card-text>{{ confirmationText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#D32F2F"
            text
            @click="confirmationDialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="#424242"
            text
            @click="confirmAction"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    selectedItems: Array,
  },
  data: () => ({
    statusItems: [
      { text: 'Enviar dados', value: true },
      { text: 'Cancelar pedidos', value: false },
    ],
    confirmationDialog: false,
    confirmationTitle: '',
    confirmationText: '',
    actionType: '',
    actionValue: null,
  }),
  methods: {
    openConfirmation(type, value) {
      this.actionType = type
      this.actionValue = value

      if (type === 'status') {
        this.confirmationTitle = 'Confirmar ação'
        this.confirmationText = `Deseja confirmar alteração de status?`
      }

      this.confirmationDialog = true
    },

    openDeleteConfirmation() {
      this.confirmationTitle = 'Confirmar Exclusão'
      this.confirmationText = `Deseja mesmo excluir ${this.selectedItems.length} destes pedidos?`
      this.actionType = 'delete'
      this.confirmationDialog = true
    },

    confirmAction() {
      this.confirmationDialog = false

      if (this.actionType === 'status') {
        this.updateMultipleStatus(this.actionValue)
      } else if (this.actionType === 'delete') {
        this.deleteMultiple()
      }
    },

    updateMultipleStatus(action) {
      let selectedIds = this.selectedItems.map((item) => item.id)
      let status = action ? 'send' : 'cancel' 

      this.$api
        .post('order/status/mass', {
          ids: selectedIds,
          status,
          obs_status: 'Ação em massa realizada',
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Status alterado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
              this.$emit('modalClicked')
              window.location.reload()
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar alterar status',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
            },
          })
        })
    },
    deleteMultiple() {
      let selectedIds = this.selectedItems.map((item) => {
        return item.id
      })
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: `Deseja mesmo deletar ${this.selectedItems.length} usuários?`,
        confirm: () => {
          this.$api
            .post(`order/massdelete`, { selectedIds })
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Usuários excluídos com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', { show: false })
                  this.$emit('modalClicked')
                  window.location.reload()
                },
              })
            })
            .catch(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar excluir estes usuários',
                confirm: () => {
                  this.$store.commit('setModal', { show: false })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', { show: false })
        },
      })
    },
  },
}
</script>
